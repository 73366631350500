<template>

  <div v-if="getLezioniGenerali">

      <header>
        <h1 class="header--title">Lezioni generali</h1>
      </header>

      <div v-if="getLezioniGenerali && getLezioniGenerali.length > 0">
        <table class="table">

                  <tr class="table--header">
                        <th class="cell">
                          Ordine
                        </th>
                        <th class="cell">
                          Titolo
                        </th>
                        <th class="cell">
                          Stato
                        </th>
                        <th class="cell">
                          Risorse
                        </th>
                        <th class="cell">
                          Azioni
                        </th>
                  </tr>

                  <tr v-for="lezione in getLezioniGenerali" :key="lezione.id" class="row">
                      <td class="cell cell--top">{{ lezione.ordine }}</td>
                      <td class="cell cell--top"><strong>{{ lezione.titolo }}</strong>
                      <td class="cell cell--top">
                        <span class="badge badge--success" v-if="lezione.published">Pubblicata</span>
                        <span class="badge badge--lightgray" v-else>Bozza</span>
                      </td>
                      <td class="cell cell--top">
                          <template v-if="lezione.risorse.length > 0">
                            <div v-for="risorsa in lezione.risorse" :key="risorsa.id">
                                <ModificaRisorsa :risorsa="risorsa" :lezioneId="lezione.id"/>
                            </div>
                            <NuovaRisorsa :lezioneId="lezione.id" />
                          </template>
                          <template v-else>
                          <NuovaRisorsa :lezioneId="lezione.id"/>
                          </template>
                        </td>
                      <td class="cell cell--top"><ModificaLezioneGenerale :lezione="lezione"/></td>
                  </tr>
        </table>
      </div>
      <div v-else>
          <p>Nessuna lezione generale</p>
      </div>

      <NuovaLezioneGenerale />

  </div>
</template>

<script>
// @ is an alias to /src
import NuovaLezioneGenerale from '@/components/editor/NuovaLezioneGenerale.vue'
import NuovaRisorsa from '@/components/editor/NuovaRisorsa.vue'
import ModificaLezioneGenerale from '@/components/editor/ModificaLezioneGenerale.vue'
import ModificaRisorsa from '@/components/editor/ModificaRisorsa.vue'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'EditorLezioni',

  data: () => {
    return {
     
    }
  },
  
  components: {
    NuovaLezioneGenerale,
    NuovaRisorsa,
    ModificaLezioneGenerale,
    ModificaRisorsa,
  },


  computed: {

    ...mapGetters('lezioni',[
        'getLezioniGenerali'
     ])

  },

  methods: {
    ...mapActions('lezioni',[
        'fetchLezioni',
    ]),

  },


  beforeMount() {
    this.$store.dispatch('lezioni/fetchLezioni')
  }

}
</script>
