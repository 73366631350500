<template>
    <span>
        <div class="t-center p-2">
        <button class="button button--small" @click="modalShow = true">Nuova lezione generale</button>
        </div>
        <UiModal buttonClass="button--small" buttonLabel="Nuova lezione" :modal="modalShow" :modalShow.sync="modalShow">
                    <template v-slot:header>
                        <h4 class="modal--title">Aggiungi lezione generale</h4>
                    </template>

                    <template v-slot:form>
                        <template v-if="!loading">
                        <ErrorsAlert />
                        <div class="form">
                            <div class="field-group flex">
                                <div class="field col col-12">
                                <label>Titolo lezione</label>
                                <input type="text" v-model="titolo" ref="titolo">
                                </div>
                            </div>
                            <div class="field-group flex">
                                <div class="field col col-12">
                                <label>Testo</label>
                                <vue-editor v-model="testo" :editorToolbar="customToolbar"></vue-editor>
                                </div>
                            </div>
                             <div class="field-group flex">
                                <div class="field col col-6">
                                    <label>Ordine</label>
                                    <input type="number" v-model="ordine">
                                </div>
                                <div class="field col col-3">
                                <label>Pubblicata</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="published">
                                    <span class="slider round"></span>
                                </label>
                                </div>

                                <div class="field col col-3">
                                <label>Visibile classe</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="visibile_classe">
                                    <span class="slider round"></span>
                                </label>
                                </div>

                            </div>

                            <div class="form--action">
                             <button class="button button--annulla" @click="modalShow = false">Annulla</button>
                            <button class="button button--conferma" @click.prevent="createLezione">Salva</button>     
                            </div> 

                        </div>

                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>
                    </template>

        </UiModal>
    </span>
</template>
<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { VueEditor } from "vue2-editor";

import { mapActions } from 'vuex'

export default {
    name: 'NuovaLezioneGenerale',

    data() {
        return {
            titolo: '',
            loading: false,
            testo: '',
            published: 0,
            visibile_classe: 1,
            ordine: '999',
            modalShow: false,
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]
        }
    },

    watch: {
    // autofocus sull'input
    modalShow(val){
        if (val == true){
            this.$nextTick(() => {
                this.$refs.titolo.focus()
            })
        }
      }
    },

    components: {
        VueEditor,
        ErrorsAlert
    },

    methods: {
        ...mapActions('lezioni',[
            'addLezione',
            'fetchLezioni'
        ]),

        createLezione () {
            this.loading = true
            this.addLezione({
                titolo: this.titolo,
                testo: this.testo,
                published: this.published,
                visibile_classe: this.visibile_classe,
                ordine: this.ordine,
            }).then((response) => {
                    this.titolo = ''
                    this.testo = ''
                    this.$store.dispatch('lezioni/fetchLezioni')
                    this.modalShow = false
                    this.loading = false
            }).catch(e => {
                    this.loading = false
                    this.$store.state.success = null;
                    this.$store.state.errors = e.response.data;
                })
            }

    }
}
</script>